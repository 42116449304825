import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c(VCard,{key:_vm.edit,staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Update Team ")]),_c(VCardText,[_c(VCombobox,{attrs:{"search-input":_vm.search,"hide-selected":"","label":"Teams","multiple":"","persistent-hint":"","small-chips":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item)?_c(VChip,_vm._b({attrs:{"color":"indigo","dark":"","input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" mdi-close ")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.search),expression:"!!search"}]},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create "),(_vm.search)?_c(VChip,{attrs:{"color":"indigo","small":"","dark":""}},[_vm._v(_vm._s(_vm.search))]):_vm._e()],1)],1)],1)]},proxy:true}]),model:{value:(_vm.teams),callback:function ($$v) {_vm.teams=$$v},expression:"teams"}}),_c('BaseFormAction',{attrs:{"submit-label":"Update","x-large":false,"small":""},on:{"click":function($event){return _vm.updateTeam()},"cancel":function($event){return _vm.resetTeam()}}})],1)],1)],1),(_vm.loading)?_c('div',[_c(VSkeletonLoader,{attrs:{"type":"card"}})],1):_vm._e(),_c('div',{staticClass:"team-container"},[(_vm.error)?_c(VAlert,{attrs:{"type":"error","text":"","error":_vm.error}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('div',[_c('h2',{staticClass:"title mb-2 d-inline mr-3"},[_vm._v("Team:")]),(_vm.detail && _vm.detail.teams)?_c('span',_vm._l((_vm.detail.teams),function(t){return _c(VChip,{key:t,staticClass:"mx-1",attrs:{"small":"","color":"indigo","dark":""}},[_vm._v(" "+_vm._s(t)+" ")])}),1):_vm._e(),(_vm.detail && (!_vm.detail.teams || !_vm.detail.teams.length))?_c('span',[_c(VChip,{attrs:{"dark":"","color":"indigo","small":""}},[_vm._v(" No Teams")])],1):_vm._e(),_c('span',{staticClass:"ml-3"},[_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showEditTeam()}}},[_vm._v("mdi-pencil")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }