<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </div>
    <div class="list-container" v-else>
      <h2 class="title mb-2">Mailing Lists</h2>
      <div class="list-items">
        <div v-for="(ml, index) in mailingLists" :key="index">
          <v-checkbox
            v-model="ml.emails"
            :value="email"
            :label="ml.title"
            @change="onListChange(ml)"
          ></v-checkbox>
        </div>
      </div>
      <div class="list-footer">{{ selected }} Selected</div>
    </div>
  </div>
</template>

<script>
import Email from '../../../services/email';
const email = new Email();
export default {
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mailingLists: [],
      loading: true
    };
  },
  created() {
    this.getMailingList();
  },
  computed: {
    selected() {
      return this.mailingLists.filter(ml => ml.emails.includes(this.email)).length;
    }
  },
  methods: {
    async getMailingList(user) {
      this.loading = true;
      const res = await email.listMailingList({ email: user });
      const data = [];
      res.forEach(ml => {
        data.push({ ...ml.data(), id: ml.id });
      });
      this.mailingLists = data;
      this.loading = false;
    },
    onListChange(ml) {
      const id = ml.id;
      const data = { ...ml };
      delete data.id;
      email.updateMailingList(id, data);
    }
  }
};
</script>

<style scoped lang="scss">
$border-color: rgb(207, 204, 204);
.list-items {
  max-height: 200px;
  overflow: scroll;
  border: 1px solid $border-color;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
}
.list-footer {
  padding: 10px;
  border: 1px solid $border-color;
  border-top: 0px;
  border-radius: 0px 0px 5px 5px;
  background-color: rgba(243, 240, 240, 0.719);
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
