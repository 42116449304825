<template>
  <div>
    <v-dialog v-model="edit" max-width="400">
      <v-card class="mx-auto" :key="edit">
        <v-card-title> Update Team </v-card-title>
        <v-card-text>
          <v-combobox
            v-model="teams"
            :search-input.sync="search"
            hide-selected
            label="Teams"
            multiple
            persistent-hint
            small-chips
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip v-if="item" v-bind="attrs" color="indigo" dark :input-value="selected" small>
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon small @click="parent.selectItem(item)"> mdi-close </v-icon>
              </v-chip>
            </template>

            <template v-slot:no-data>
              <v-list-item v-show="!!search">
                <v-list-item-content>
                  <v-list-item-title>
                    Press <kbd>enter</kbd> to create
                    <v-chip v-if="search" color="indigo" small dark>{{ search }}</v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
          <BaseFormAction
            submit-label="Update"
            :x-large="false"
            small
            @click="updateTeam()"
            @cancel="resetTeam()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="loading">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </div>
    <div class="team-container">
      <v-alert type="error" text v-if="error" :error="error">
        {{ error }}
      </v-alert>
      <div>
        <h2 class="title mb-2 d-inline mr-3">Team:</h2>
        <span v-if="detail && detail.teams">
          <v-chip class="mx-1" small v-for="t in detail.teams" :key="t" color="indigo" dark>
            {{ t }}
          </v-chip>
        </span>
        <span v-if="detail && (!detail.teams || !detail.teams.length)">
          <v-chip dark color="indigo" small> No Teams</v-chip>
        </span>
        <span class="ml-3">
          <v-icon @click="showEditTeam()" color="primary">mdi-pencil</v-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../../../services/user';
const user = new User();
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      detail: null,
      loading: true,
      error: '',
      edit: false,
      teams: [],
      search: ''
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async updateTeam() {
      await user.updateProfile(this.id, { teams: this.teams });
      this.edit = false;
      this.getData();
    },
    showEditTeam() {
      this.edit = true;
      if (this.detail && this.detail.teams) {
        this.teams = [...this.detail.teams];
      }
      this.search = '';
    },
    resetTeam() {
      this.teams = [];
      this.search = '';
      this.edit = false;
    },
    async getData() {
      this.error = '';
      try {
        const { data } = await user.getProfile(this.id);
        this.detail = { team: [], ...data };
      } catch (error) {
        if (error && error.message) {
          this.error = error.message;
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
