<template>
  <v-main>
    <!-- <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs> -->
    <BaseBreadCrumb admin :current-page="userName" />
    <v-dialog max-width="600" v-model="dialogs.login">
      <v-card v-if="user">
        <v-card-title>
          Login as {{ user.email }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.login = false"> <v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="loadings.login" class="text-center my-4">
            <base-progress-circular></base-progress-circular>
          </div>
          <div v-else>
            <div v-if="errors.login">
              <v-alert type="error">
                {{ errors.login }}
              </v-alert>
              <v-btn text color="purple" @click="loginAsUser(user)"
                >Retry <v-icon>mdi-reload</v-icon></v-btn
              >
            </div>
            <div v-else>
              <p>
                Open this link in incognito window to login as
                {{ user.email }}
              </p>
              <a :href="loginURL()">{{ loginURL().substring(0, 100) }}+...</a>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <div v-if="user">
        <v-card class="mx-auto">
          <v-card-title class="primary--text"> User Detail </v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <p>Email: {{ user.email }}</p>
                <p>Name: {{ user.first_name }} {{ user.last_name }}</p>
                <p>Last Login: {{ formatDate(user.last_login) }}</p>
                <div>
                  <v-checkbox
                    v-model="logSession"
                    @change="updateLogSession"
                    label="Log Session"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <UserTeam class="mb-5" :id="$route.params.id" />
                <v-divider class="pb-5"></v-divider>
                <UserMailingList :email="user.email" />
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-divider v-if="$vuetify.breakpoint.smAndDown" class="mb-2"></v-divider>
                <h2 class="title mb-2">Email History</h2>
                <BaseScrollable
                  max-height="70vh"
                  class="mt-4"
                  :outlined="$vuetify.breakpoint.mdAndUp"
                >
                  <EmailHistory :email="user.email" :highlight-email="user.email" />
                </BaseScrollable>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn
              :href="`mailto:${user.email}`"
              target="_blank"
              large
              color="primary"
              text
              class="v-btn--active"
              >Send Email <v-icon>mdi-email</v-icon></v-btn
            >
            <v-btn @click="loginAsUser(user)" color="purple" large text class="v-btn--active">
              Login
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div v-else>
        <v-skeleton-loader type="card" max-width="600" class="mx-auto"></v-skeleton-loader>
      </div>
      <h1 class="primary--text title">Parcels</h1>
      <div class="parcels">
        <div>
          <div>
            <update-geo-data :values="parcelList.map(e => e.id)"></update-geo-data>
          </div>
          <v-row v-if="parcelList.length">
            <v-col
              sm="6"
              md="4"
              lg="3"
              xl="2"
              cols="12"
              v-for="(parcel, index) in parcelList"
              :key="index"
            >
              <parcel-detail
                @update="updateParcels()"
                :admin="true"
                v-if="parcel"
                :data="parcel"
              ></parcel-detail>
            </v-col>
          </v-row>
          <v-row v-else-if="loading">
            <v-col v-for="(i, index) in 4" :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <div class="text-center" v-else>
            <v-alert color="info" text>No Parcels Added Yet.<br /> </v-alert>
          </div>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import admin from '@/mixins/admin';
import Moment from 'moment';
import parcel from '@/mixins/parcel';
import ParcelDetail from '@/components/Parcel/Detail';
import UpdateGeoData from '@/components/Admin/UpdateGeoData';
import UserMailingList from './MailingList.vue';
import UserTeam from './Team.vue';

import config from '../../../config';
import Vue from 'vue';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import User from '../../../services/user';

mapboxgl.accessToken = config.mapboxToken;
Vue.prototype.$mapbox = mapboxgl;

export default {
  mixins: [admin, parcel],
  components: {
    UserTeam,
    ParcelDetail,
    UpdateGeoData,
    UserMailingList,
    EmailHistory: () => import('../Email/History.vue')
    // BaseBreadcrumb: () => import('../../../components/Core/Base/BreadCrumb.vue')
  },
  data: () => ({
    dialogs: {
      login: false
    },
    loadings: {
      login: false
    },
    errors: {
      login: ''
    },
    token: '',
    breadcrumbs: [
      { text: 'Admin', to: '/admin/dashboard', exact: true },
      { text: 'User', to: '/admin/user/id', disabled: true },
      { text: 'Loading', to: '/admin/user/id', disabled: true }
    ],
    user: null,
    parcelList: [],
    loading: true,
    logSession: false
  }),
  computed: {
    userName() {
      try {
        return this.user.first_name;
      } catch {
        return '...';
      }
    }
  },
  async mounted() {
    await this.updateUser();
    await this.updateParcels();
    this.breadcrumbs[2].text = this.user.email;
  },
  methods: {
    updateLogSession(value) {
      const u = new User();
      u.updateProfile(this.$route.params.id, { logSession: value });
      this.logSession = value;
    },
    loginURL() {
      return `${window.location.origin}/login?token=${this.token}`;
    },
    loginAsUser(user) {
      const api = config.api();
      const url = `${api.cloudFunctionAPI}/admin/token?uid=${this.$route.params.id}`;
      this.dialogs.login = true;
      this.loadings.login = true;
      this.errors.login = '';
      this.$axios
        .get(url)
        .then(res => {
          this.token = res.data.token;
        })
        .catch(err => {
          this.errors.login = err;
        })
        .finally(() => {
          this.loadings.login = false;
        });
    },
    async updateUser() {
      let u = await this.getUserDetail(this.$route.params.id);
      this.user = u;
      this.logSession = u.logSession || false;
      if (!u) {
        this.$router.push('/admin');
      }
    },
    async updateParcels() {
      let p = await this.getParcelList(this.$route.params.id);
      let data = [];
      if (!p.empty) {
        p.forEach(e => {
          data.push({ ...e.data(), id: e.id });
        });
      }
      this.parcelList = data;
      this.loading = false;
    },
    formatDate(ts) {
      let lastLogin = null;
      if (typeof ts === 'object') {
        lastLogin = new Date(ts.seconds * 1000);
      } else {
        lastLogin = new Date(ts);
      }
      if (lastLogin == 'Invalid Date') {
        return '';
      }
      return Moment(lastLogin).format('lll');
    }
  }
};
</script>

<style></style>
